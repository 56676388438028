<template>
  <v-card class="main-card">

    <v-row class="px-6">

      <v-col class="mt-3" cols="3">
        <h3 class="page-title">LOCATION ANALYSIS</h3>
        <h5 class="light-gray-text">Analysis of all active field officers.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>

      <v-col class="px-4 mt-5" cols="7">
        <v-row class="tool-bar-style">
          <v-col cols="12" md="3">
            <v-btn variant="outlined" class="pa-1 d-flex" width="150" height="70" @click="allAgents()">
              <v-card width="150" height="70" class="pb-12" >
                <v-row>
                  <v-col cols="12" md="6" style="font-size: 12px">
                      <div class="mt-2 ml-3">ALL</div>
                      <!-- <div class=" ml-3 font-weight-bold text-red " style="font-size: 20px">{{ agents.length }}</div> -->
                  </v-col>
                  <v-col cols="12" md="6" >
                    <v-img style="width: 5vw; height: 9vh" src="@/WEB/ICONS/all_vechile.png" right color="red" class="bg-red-lighten-5" ></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn variant="outlined" class="pa-1 d-flex" width="150" height="70">
              <v-card width="150" height="70" class="pb-12" >
                <v-row>
                  <v-col cols="12" md="6" style="font-size: 12px;color: #057b0a">
                    <div class="mt-2 ml-3">ACTIVE</div>
                    <div class=" ml-3 font-weight-bold text-black " style="font-size: 20px">{{ this.activeOfficerCount }}</div>
                  </v-col>
                  <v-col cols="12" md="6" >
                    <v-img style="width: 5vw; height: 9vh" src="@/WEB/ICONS/active_vechile.png" right color="green" class="bg-green-lighten-2" ></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-btn>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn variant="outlined" class="pa-1 d-flex" width="150" height="70" @click="idling()">
              <v-card width="150" height="70" class="pb-12" >
                <v-row>
                  <v-col cols="12" md="6" style="font-size: 12px">
                    <div class="mt-2 ml-3">INACTIVE</div>
                    <div class=" ml-3 font-weight-bold text-red " style="font-size: 20px">{{ this.deactiveOfficerCount  }}</div>
                  </v-col>
                  <v-col cols="12" md="6" >
                    <v-img style="width: 5vw; height: 9vh" src="@/WEB/ICONS/inactive_vechile.png" right color="red" class="bg-red-lighten-5" ></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="px-4 mt-5" cols="2">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>

    </v-row>

    <v-card class="px-2 mx-1 pb-14"  style="padding: 20px; margin-top: 5px; margin-bottom: 20px; overflow-y: scroll; height: 100%">
      <v-row class="mt-1">
        <v-col cols="4">
          <v-select
              v-model="outlet.province"
              :items="provinces"
              :loading="provinceLoading"
              label="Province"
              placeholder="Select province"
              variant="outlined"
              @update:modelValue="getAllDistrictAccProv"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="outlet.district"
              :items="districts"
              :loading="mDistrictLoading"
              label="District"
              placeholder="Select district"
              variant="outlined"
              @update:modelValue="getAllDsd"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="outlet.dsd"
              :items="dsdList"
              :loading="mDSDLoading"
              label="DSD"
              placeholder="Select DSD"
              variant="outlined"
              @update:modelValue="getAllGnd"

          ></v-select>
        </v-col>
      </v-row>

      <v-row >
        <v-col cols="4">
          <v-select
              v-model="outlet.gnd"
              :items="gndList"
              label="GND"
              placeholder="Select GND"
              variant="outlined"
              :loading="gndLoading"
          ></v-select>
        </v-col>

        <v-col cols="8">

          <v-select
              
              clearable
              :item="allOfficerDetails"
              item-title="name"
              item-value="name"
              v-model="ofiicreValue"
              label="Select Officers"
              multiple
              placeholder="Select Officer"
              variant="outlined"
          >

          </v-select>
        </v-col>
      </v-row>


    <!-- Main Content -->
    <v-row class="mapRow" style="margin-bottom: 30px; height: 100%; width: 100%;" >
      <!-- Map Section -->
      <v-col cols="8" >
        <v-card style="margin-bottom: 10px; height: 100%; width: 100%;">
          <GMapMap 
            class="d-flex map" 
            :center="mapCenter" 
            :zoom="7" 
            style="width: 100%; height: 76vh;">
            
            <GMapMarker  :key="index"
               v-for="(m, index) in markers" 
               :position="m.position" 
               @click="selectAgent(m)"
               :icon="{
                      url: require('@/assets/ICONS/vechile_icon.png'), 
                      scaledSize: { width: 30, height: 25 }, 
                  }" 

            > 

            <GMapInfoWindow 
              @closeclick="closeInfoWindow" 
              :opened="selectedAgentsamplee && selectedAgentsamplee.id === m.id"
              >
              <v-row >
                  <v-card class="map-card mt-0">
                    <v-card-title>
                      <!-- <v-avatar class="mr-3">
                        <v-img :src="selectedAgent.avatar"></v-img>
                      </v-avatar> -->
                      <span>{{ this.officerDetails.name }}</span><br>
                      <p style="color: red; size: 5px;">Reg Num: 4855</p>
                    </v-card-title>
                    <v-card-text>
                      <v-card-text>{{ this.officerDetails .location }}<br>
                        <span>Last updated: {{ this.officerDetails.lastUpdated }}</span></v-card-text>
                      <v-divider></v-divider>
                      <v-divider class="my-3"></v-divider>
                    </v-card-text>



                   



                  </v-card>
                </v-row>

            </GMapInfoWindow>


            </GMapMarker>
          </GMapMap>
        </v-card>
      </v-col>

      <!-- Agents List Section -->
      <v-col cols="12" md="4" class="listColum">
        <v-card class="listCard pa-1" flat>
          <v-card-title class="text-grey">LIST OF OFFICERS</v-card-title>
          <v-card-subtitle>List of all live Officer & their Details.</v-card-subtitle>
          
          <v-list>
            <v-list-item >
              <v-card elevation="16" class="pa-2" v-for="agent in this.allOfficerDetails " :key="agent.id" >
              <v-row class="mt-2">
                <v-col cols="12" md="2" >
                  <v-avatar>
                    <v-img src="@/assets/ASSETS/PERSONS/person-3.jpg" alt="Avatar"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <v-list-item-content>
                    <v-list-item-title>{{ agent.name }}</v-list-item-title>
                    <!-- <v-list-item-subtitle>{{ agent.vehicle }}</v-list-item-subtitle> -->
                    <v-list-item-subtitle class="list-RegNum">Reg num: Reg0110002</v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
               
              </v-row>
            </v-card>
            </v-list-item>
          </v-list>
          
        </v-card>
      </v-col>
    </v-row>
  </v-card>
  </v-card>
</template>

<script>

import { GMapMap, GMapMarker, google  } from '@fawmi/vue-google-maps';
import axios from "axios";


export default {
  name: 'TrackingAnalysis',
  components: {
    GMapMap, GMapMarker, 
  },
  data() {
    return {
      
      selectedAgentsamplee: null,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],

      mDistrictLoading: false,
      provinceLoading:false,
      gndLoading:false,
      mDSDLoading: false,
      mGNDLoading: false,

      outlet: {
        province: '',
        district: '',
        dsd: '',
        gnd: '',

      },

      allOfficerDetails:[],
      activeOfficerCount:0,
      deactiveOfficerCount:0,

      agents: [
        {
          id: "",
          name:"",
          regNum: "",
          vehicle: "",
          avatar: "",
          trackingEnabled: true,
          position: { lat: null, lng: null },
          location: "",
          lastUpdated: "",
          trips: [
            { id: "", destination: "", time: "" },
            { id: "", destination: "", time: "" },
          ],
        }
      ],

      mapCenter: { lat: 6.753174417177423, lng: 80.76603582220012 },
      selectedAgent: {},
      dialog: false,
      
      markers: [
        {
          agentId:"",
          itineraryId:"",
          outletId:"",
          position: {
            lat:"" , lng: ""
          },
        }
      ],

      // carIcon: {
      //   url: 'path/to/your/car-image.png', 
      //   // scaledSize: new google.maps.Size(50, 50), 
      //   // origin: new google.maps.Point(0, 0), 
      //   // anchor: new google.maps.Point(25, 50) 
      // }

      officerDetails:{},
      outletDetails:[],

    };
  },
  

  watch(){

  },

  mounted() {
    this.getAllProvinces();
    this.getAllAgentAndCount();

    if (typeof google !== 'undefined' && google.maps) {
    // API is loaded, you can safely call updateUser now or later as needed
      console.log('fkkkk');
      } else {
        console.error('Google Maps API is not loaded yet.');
      }
      
        window.Echo.channel('TracingApp')
            .listen('LocationUpdated', (e) => {
                console.log('User updated:', e.location);
                // Update the dashboard with the new user data
                this.updateUser(e.location);
            });
    
    },

  methods: {
   

    updateUser(vehicle) {
           
            const position = { 
              lat: vehicle.latitude, 
              lng: vehicle.longitude 
            };

            console.log('tracking',position);

            this.markers = [
              {
                agentId:vehicle.agent_id,
                itineraryId:vehicle.itinerary_id,
                outletId:vehicle.outlet_id,
                position: {
                  lat: position.lat,
                  lng: position.lng
                }
              }
            ];
                  
        },
    

    selectAgent(agent) {
      console.log('fkfkfll',agent);
      setTimeout(() => {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("agentId", agent.agentId);
      formData.append("itineraryId", agent.itineraryId);
      formData.append("outletId", agent.outletId);

      axios.post('/api/getDetaisFromAgent',formData,{
        headers: {
                'Authorization': `Bearer ${token}`
            }

        })
          .then(response => {
            if (response.data.http_status === 'success') {
              
              this.officerDetails = response.data.officerDetails;
              console.log("fk1",this.officerDetails);
              this.outletDetails = response.data.outletDetails;
              console.log("fk2", this.outletDetails);

              this.selectedAgentsamplee = agent; 
              this.dialog = true;
            }
          })
          .catch(() => {
            console.log("fk2");
          });
        }, 0); 

      // this.selectedAgent = agent;
      
    },
    closeInfoWindow() {
    this.selectedAgent = null; // Reset the selected agent to close the InfoWindow
  },
    toggleEnable(agent) {
      agent.trackingEnabled = !agent.trackingEnabled;
    },


    allAgents() {
      this.$router.push('/all-agents');
    },
    idling(){
      this.$router.push('/idling-agents');
    },
    back(){
      this.$router.push('/live-tracking');
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.provinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.provinceLoading = false;
            }
          })
          .catch(() => {
            this.provinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.gndLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.gndLoading = false;
            }
          })
          .catch(() => {
            this.gndLoading = false;
          });
    },

    // Get All Agent Details and Active Deactive Ofiicer Count
    getAllAgentAndCount() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 

      axios.post('/api/getAllAgentAndCount', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          this.allOfficerDetails = response.data.ofiicerDetails;
          this.activeOfficerCount = response.data.getActiveOfiicer;
          this.deactiveOfficerCount = response.data.getDeactiveOfiicer;
          
          console.log('data',this.allOfficerDetails);
          console.log('data1',this.activeOfficerCount);
          console.log('data2',this.deactiveOfficerCount);
        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
      },

    
  },
};
</script>




<style scoped>

.map {
  width: 100%;
  height: 100%;

}
.map-card {
  width: 400px;

}

.mapRow{
  padding: 10px;


}

.listColum{
  width: auto;
}
.e-button{
  font-size: 9px;
  font-weight: bold;
}
.BTNswitch{
  width: 100px;

}
.list-RegNum{
  color: #BB0000;
}
.gm-ui-hover-effect {
  top: 10px !important; /* Adjust top position */
  right: 10px !important; /* Adjust right position */
}

.main-card{
  width:100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}


</style>









